var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading.all ? _c('div', [_c('div', {
    staticClass: "task-container pa-9 pa-md-6 pa-lg-9"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "mb-4",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h3', {
    staticClass: "page-title font-weight-bold mb-0"
  }, [_vm._v("タスク詳細")])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" Task ID: " + _vm._s(_vm.taskDetails.id) + " ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12",
      "id": "detailPart"
    }
  }, [_c('div', {
    staticClass: "text-title text-title-2 text-heading-2 font-weight-bold"
  }, [_vm._v(" 日付 / チーム / メモ ")]), _c('div', {
    staticClass: "horizontal-line"
  })]), _c('v-col', [_c('div', {
    staticClass: "form-content"
  }, [_c('detail', {
    attrs: {
      "name": _vm.task.name,
      "task": _vm.taskDetails
    },
    on: {
      "update:name": function updateName($event) {
        return _vm.$set(_vm.task, "name", $event);
      }
    }
  })], 1)])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "id": "customerPart"
    }
  }, [_c('div', {
    staticClass: "text-title text-title-2 text-heading-2 font-weight-bold"
  }, [_vm._v(" 顧客 / 台帳 ")]), _c('div', {
    staticClass: "horizontal-line"
  })]), _c('v-col', [_c('div', {
    staticClass: "form-content"
  }, [_c('customer', {
    attrs: {
      "name": _vm.task.name,
      "task": _vm.taskDetails,
      "expanded": true,
      "loading": _vm.loading
    },
    on: {
      "update:name": function updateName($event) {
        return _vm.$set(_vm.task, "name", $event);
      }
    }
  })], 1)])], 1)], 1)], 1), _c('div', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: _vm.onIntersect,
      expression: "onIntersect"
    }],
    staticClass: "intersection-helper"
  }), _c('div', {
    staticClass: "create-task-footer px-6",
    class: !_vm.isIntersecting ? 'shadow' : ''
  }, [_c('v-row', [_c('v-col', {
    staticClass: "col-sm-2 col-lg-4",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-btn', {
    attrs: {
      "to": {
        name: 'TaskEdit',
        params: {
          id: _vm.taskDetails.id
        }
      },
      "outlined": "",
      "color": "#2F80ED"
    }
  }, [_vm._v(" 編集する ")])], 1), _c('v-col', {
    staticClass: "col-sm-6 col-lg-4 text-center",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-btn', {
    staticClass: "mr-3",
    attrs: {
      "color": _vm.on.detail ? 'rgba(79, 85, 167, 0.45)' : 'rgba(79, 85, 167, 0.1)',
      "depressed": "",
      "rounded": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo('#detailPart', {
          offset: _vm.offset
        });
      }
    }
  }, [_vm._v(" 日付 / チーム / メモ ")]), _c('v-btn', {
    attrs: {
      "color": _vm.on.customer ? 'rgba(79, 85, 167, 0.45)' : 'rgba(79, 85, 167, 0.1)',
      "depressed": "",
      "rounded": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo('#customerPart', {
          offset: _vm.offset
        });
      }
    }
  }, [_vm._v(" 顧客 / 台帳 ")])], 1), _c('v-col', {
    staticClass: "d-flex justify-end col-sm-4 col-lg-4",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-btn', {
    staticClass: "mr-3",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "text": ""
    }
  }, [_vm._v("キャンセル")]), _c('v-btn', {
    attrs: {
      "loading": _vm.loading.button,
      "outlined": "",
      "color": "#2F80ED"
    },
    on: {
      "click": _vm.sendEmail
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "32",
      "left": ""
    }
  }, [_vm._v("$sendMail")]), _vm._v(" 通知 ")], 1)], 1)], 1)], 1)]) : _c('div', [_c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "size": "48",
      "indeterminate": ""
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }