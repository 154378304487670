var render = function () {
  var _vm$task;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "mb-6",
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "detail-header font-weight-bold mb-4"
  }, [_vm._v(" " + _vm._s(_vm.task.date) + " （" + _vm._s(_vm.getDateFormat(_vm.task.date)) + "） " + _vm._s(_vm.task.task_start_time) + " 開始 "), _vm.task && _vm.task.task_end_time ? _c('span', [_vm._v(" " + _vm._s((_vm$task = _vm.task) === null || _vm$task === void 0 ? void 0 : _vm$task.task_end_time) + " 完了時刻 ")]) : _vm._e()]), _c('div', {
    staticClass: "detail-subheader"
  }, [_vm._v("集合 " + _vm._s(_vm.task.gathering_time) + " @ " + _vm._s(_vm.task.gathering_place))]), _vm.weatherInfo ? _c('div', {
    staticClass: "mt-4"
  }, [_c('v-row', {
    staticClass: "mb-2",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-avatar', {
    class: _vm.weatherBackgroundClass
  }, [_c('v-img', {
    attrs: {
      "src": _vm.weatherInfo,
      "max-width": "60",
      "contain": ""
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" " + _vm._s(_vm.weatherDescription) + " ")])], 1), _c('div', {
    staticClass: "weather-last-update"
  }, [_vm._v(" 最終更新：" + _vm._s(_vm.weatherLastUpdate) + " ")])], 1) : _vm._e()]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6",
      "align-self": "center"
    }
  }, [_c('v-avatar', {
    attrs: {
      "color": _vm.task.service.background_color,
      "size": "64"
    }
  }, [_c('span', {
    style: "color: ".concat(_vm.task.service.font_color)
  }, [_vm._v(" " + _vm._s(_vm.task.service.name) + " ")])])], 1), _vm.typeExists(_vm.task.task_member, 0) || _vm.typeExists(_vm.task.task_member, 1) || _vm.typeExists(_vm.task.task_member, 2) ? _c('v-col', {
    staticClass: "detail-header-participant text-heading-3",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 参加メンバー ")]) : _vm._e(), _c('v-col', {
    staticClass: "mb-6",
    class: {
      'no-members-case': !_vm.task.task_member.length
    },
    attrs: {
      "cols": "12"
    }
  }, [_vm.task.task_member.length && _vm.typeExists(_vm.task.task_member, 0) ? [_c('v-row', {
    staticClass: "mb-2"
  }, [_c('v-col', [_c('div', {
    staticClass: "detail-content-participant-header text-heading-3"
  }, [_vm._v(" 通常業務 ")]), _c('div', {
    staticClass: "horizontal-line"
  })])], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.getMembers(_vm.taskMembers, 0), function (member) {
    return _c('v-col', {
      key: "participant-".concat(member.id),
      staticClass: "text-center",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-avatar', {
      class: _vm.isLeader(member) ? 'leader' : '',
      attrs: {
        "size": "51"
      }
    }, [_c('img', {
      attrs: {
        "alt": "avatar",
        "src": _vm.getMemberPhoto(member)
      }
    })]), _c('div', {
      staticClass: "participant-name text-center text-heading-3 mt-2"
    }, [_vm._v(" " + _vm._s(member.last_name) + " ")])], 1);
  })], 2)] : _vm._e(), _vm.task.task_member.length && _vm.typeExists(_vm.task.task_member, 1) ? [_c('v-row', {
    staticClass: "mb-2"
  }, [_c('v-col', [_c('div', {
    staticClass: "detail-content-participant-header text-heading-3"
  }, [_vm._v(" 日常清掃員 ")]), _c('div', {
    staticClass: "horizontal-line"
  })])], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.getMembers(_vm.taskMembers, 1), function (member) {
    return _c('v-col', {
      key: "participant-".concat(member.id),
      staticClass: "text-center",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-avatar', {
      class: _vm.isLeader(member) ? 'leader' : '',
      attrs: {
        "size": "51"
      }
    }, [_c('img', {
      attrs: {
        "alt": "avatar",
        "src": _vm.getMemberPhoto(member)
      }
    })]), _c('div', {
      staticClass: "participant-name text-center text-heading-3 mt-2"
    }, [_vm._v(" " + _vm._s(member.last_name) + " ")])], 1);
  })], 2)] : _vm._e(), _vm.task.task_member.length && _vm.typeExists(_vm.task.task_member, 2) ? [_c('v-row', {
    staticClass: "mb-2"
  }, [_c('v-col', [_c('div', {
    staticClass: "detail-content-participant-header text-heading-3"
  }, [_vm._v(" 協業会社 ")]), _c('div', {
    staticClass: "horizontal-line"
  })])], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.getMembers(_vm.taskMembers, 2), function (member) {
    return _c('v-col', {
      key: "participant-".concat(member.id),
      staticClass: "text-center",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-avatar', {
      class: _vm.isLeader(member) ? 'leader' : '',
      attrs: {
        "size": "51"
      }
    }, [_c('img', {
      attrs: {
        "alt": "avatar",
        "src": _vm.getMemberPhoto(member)
      }
    })]), _c('div', {
      staticClass: "participant-name text-center text-heading-3 mt-2"
    }, [_vm._v(" " + _vm._s(member.last_name) + " ")])], 1);
  })], 2)] : _vm._e()], 2), _vm.task.task_freelancer_users.length ? _c('v-col', {
    staticClass: "mb-6",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', [_c('div', {
    staticClass: "detail-content-participant-header text-heading-3"
  }, [_vm._v(" 外注先 ")]), _c('div', {
    staticClass: "horizontal-line"
  })])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-heading-3",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.task.task_freelancer_users.length ? [_c('v-row', _vm._l(_vm.task.task_freelancer_users, function (outsource) {
    return _c('v-col', {
      key: outsource.name,
      staticClass: "text-center",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-avatar', {
      attrs: {
        "size": "46"
      }
    }, [_c('img', {
      attrs: {
        "alt": "avatar",
        "src": _vm.getPartnerProfilePicture(outsource)
      }
    })]), _c('div', {
      staticClass: "participant-name mt-2"
    }, [_vm._v(" " + _vm._s(outsource.name) + " "), _c('span', {
      staticClass: "gray--text"
    }, [_vm._v(" (" + _vm._s(outsource.pivot.freelancers_count) + ") ")])])], 1);
  }), 1)] : [_vm._v(" - ")]], 2)], 1)], 1) : _vm._e(), _vm.task.car ? _c('v-col', {
    staticClass: "car-info mb-6",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "detail-content-participant-header-2 mb-4 text-heading-2"
  }, [_vm._v(" 車 ")]), _c('div', {
    staticClass: "d-inline-block text-heading-3"
  }, [_vm._v(" " + _vm._s(_vm.task.car ? _vm.task.car.car_class + ' ' + _vm.task.car.number_plate : '-') + " ")]), _vm.task.ETC_card ? _c('div', {
    staticClass: "d-inline-block ml-8"
  }, [_c('span', [_vm._v("ETC")]), _c('v-avatar', {
    staticClass: "ml-4",
    class: _vm.task.ETC_card ? 'white--text' : '',
    attrs: {
      "size": "32",
      "color": _vm.task.ETC_card ? '#4F55A7' : '#DEDEDE'
    }
  }, [_vm._v(" " + _vm._s(_vm.task.ETC_card ? '有' : '無') + " ")])], 1) : _vm._e(), _vm.task.gasoline_card ? _c('div', {
    staticClass: "d-inline-block ml-8"
  }, [_c('span', [_vm._v("ガソリンカード")]), _c('v-avatar', {
    staticClass: "ml-4",
    class: _vm.task.gasoline_card ? 'white--text' : '',
    attrs: {
      "size": "32",
      "color": _vm.task.gasoline_card ? '#4F55A7' : '#DEDEDE'
    }
  }, [_vm._v(" " + _vm._s(_vm.task.ETC_card ? '有' : '無') + " ")])], 1) : _vm._e()]) : _vm._e(), _vm.task.memo ? _c('v-col', {
    staticClass: "memo-info mb-6",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "detail-content-participant-header-2 text-heading-2 mb-4"
  }, [_vm._v(" 通常メモ ")]), _c('div', {
    staticClass: "memo text-heading-3"
  }, [_c('p', [_vm._v(_vm._s(_vm.task.memo ? _vm.task.memo : '-'))])])]) : _vm._e(), _vm.task.important_notice ? _c('v-col', {
    staticClass: "memo-info mb-6",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "detail-content-participant-header-2 text-heading-2 mb-4"
  }, [_vm._v(" 重要連絡 ")]), _c('div', {
    staticClass: "memo-important text-heading-3"
  }, [_c('p', [_vm._v(_vm._s(_vm.task.important_notice ? _vm.task.important_notice : '-'))])])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }