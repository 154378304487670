<template>
  <div v-if="!loading">
    <template v-if="task.service.id === 0">
      <!-- TODO: implement the same logic to display the data field as the 'building' service_type
                 to keep the lines of code as compact as possible
      -->
      <div class="info-box mb-6 pa-8" v-if="
        (task.project.svc_coating.floor_color &&
          task.project.svc_coating.floor_color.length) ||
        (coatingFloorType && coatingFloorType.length) ||
        (removalWork && removalWork.length) ||
        (microMop && microMop.length) ||
        (premiumCleaner && premiumCleaner.length) ||
        (toughSealer && toughSealer.length) ||
        (counterTable && counterTable.length) ||
        (assignedPartner && assignedPartner.length) ||
        (partnerWorkrange && partnerWorkrange.length)
      ">
        <v-row>
          <v-col cols="3" class="info-box-headline text-heading-2">
            <v-icon class="mr-1" color="#4F55A7" size="6">$dot</v-icon>
            作業情報
          </v-col>
          <v-col cols="9" class="flex-grow-1">
            <v-row dense>
              <v-col cols="12" class="info-box-work-information-headline top">
                {{ coatingGenre }}
                <span class="ml-4">{{ task.project.svc_coating.coating_area }} ㎡</span>
              </v-col>
              <v-col cols="12" class="info-box-work-information-headline sub">
                <template v-for="(item, index) in coatingRange">
                  {{ item.text }}
                  <template v-if="item.id">({{ item.text_value }})</template>
                  <template v-if="index !== coatingRange.length - 1">
                    /
                  </template>
                </template>
              </v-col>

              <v-col cols="12" class="mt-5">
                <v-row dense>
                  <v-col cols="4" class="info-box-work-information-content-label pb-1">床材の色</v-col>
                  <v-col cols="8" class="info-box-work-information-content-content pb-1 d-flex">
                    <template v-if="
                      task.project.svc_coating.floor_materials.length !== 0
                    ">
                      <div :key="`coating-color-${index}`" class="d-flex align-center" v-for="(floorMaterial, index) in task.project
                      .svc_coating.floor_materials">
                        <v-avatar class="mr-4" tile min-height="65" min-width="100"
                          style="border-radius: 5% !important;" :color="
                            floorMaterial.type === 0
                              ? floorMaterial.color_code
                              : ''
                          ">
                          <v-img min-height="65" min-width="100" v-if="floorMaterial.type === 1"
                            :src="floorMaterial.image_url" />
                        </v-avatar>
                        <span class="mr-4">
                          {{ floorMaterial.name }}

                          <template v-if="floorMaterial.pivot.custom_value">
                            ( {{ floorMaterial.pivot.custom_value }} )
                          </template>
                        </span>
                      </div>
                    </template>
                    <v-row v-else dense>
                      <v-col v-for="(color, index) in task.project.svc_coating
                      .floor_color" cols="auto" :key="`coating-color-${index}`" class="d-flex align-center mr-3">
                        <span :style="{
                          backgroundColor: getColorObj(
                            color.text,
                            'floor_color'
                          ).color
                        }" class="dot mr-1"></span>
                        <!-- <v-icon size="22" :color="getColorObj(color.text).color"
                          >$dot</v-icon
                        > -->
                        {{ color.text }}
                        <template v-if="color.id">
                          ({{ color.text_value }})
                        </template>
                      </v-col>
                    </v-row>
                  </v-col>
                  <template v-if="coatingFloorType">
                    <v-col cols="4" class="info-box-work-information-content-label pb-3">床材種類</v-col>
                    <v-col cols="8" class="info-box-work-information-content-content pb-3">
                      {{ coatingFloorType }} {{ task.project?.svc_coating?.floor_genre_memo ? ' (' +
                      task.project?.svc_coating?.floor_genre_memo + ')' : ''
                      }}
                    </v-col>
                  </template>
                  <template v-if="toughSealer">
                    <v-col cols="4" class="info-box-work-information-content-label">タフシーラー</v-col>
                    <v-col cols="8" class="info-box-work-information-content-content">
                      {{ toughSealer }}
                    </v-col>
                  </template>
                  <template v-if="removalWork">
                    <v-col cols="4" class="info-box-work-information-content-label pb-3">剥離作業</v-col>
                    <v-col cols="8" class="info-box-work-information-content-content pb-3">
                      {{ removalWork }} ( {{ memo }} )
                    </v-col>
                  </template>
                  <template v-if="microMop">
                    <v-col cols="4" class="info-box-work-information-content-label pb-3">マイクロモップ</v-col>
                    <v-col cols="8" class="info-box-work-information-content-content pb-3">
                      {{ microMop }}
                    </v-col>
                  </template>
                  <template v-if="premiumCleaner">
                    <v-col cols="4" class="info-box-work-information-content-label pb-3">プレミアム専用クリーナー</v-col>
                    <v-col cols="8" class="info-box-work-information-content-content pb-3">
                      {{ premiumCleaner }}
                    </v-col>
                  </template>
                  <template v-if="counterTable">
                    <v-col cols="4" class="info-box-work-information-content-label pb-3">多目的カウンター </v-col>
                    <v-col cols="8" class="info-box-work-information-content-content pb-3">
                      {{ counterTable }}
                    </v-col>
                  </template>
                  <template v-if="assignedPartner">
                    <v-col cols="4" class="info-box-work-information-content-label pb-3">外部発注</v-col>
                    <v-col cols="8" class="info-box-work-information-content-content pb-3">
                      {{ assignedPartner }}
                    </v-col>
                  </template>
                  <template v-if="partnerWorkrange">
                    <v-col cols="4" class="info-box-work-information-content-label pb-3">発注範囲</v-col>
                    <v-col cols="8" class="info-box-work-information-content-content pb-3 white-space-preline">
                      {{ partnerWorkrange }}
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <div class="info-box mb-6 pa-8" v-if="task.project.svc_coating.premium_guard.length">
        <v-row>
          <v-col cols="3" class="info-box-headline text-heading-2">
            <v-icon class="mr-1" color="#4F55A7" size="6">$dot</v-icon>
            プレミアムガード
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col v-for="(item, index) in task.project.svc_coating.premium_guard"
                class="info-box-work-information-headline sub" :key="`premium-guard-item-${index}`">
                {{ item.text }}
                <template v-if="item.text_value">
                  ({{ item.text_value }})
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <div class="info-box mb-6 pa-8" v-if="task.project.svc_coating.campaign">
        <v-row>
          <v-col cols="3" class="info-box-headline text-heading-2">
            <v-icon class="mr-1" color="#4F55A7" size="6">$dot</v-icon>
            キャンペーンサービス
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col cols="auto" class="info-box-work-information-headline sub">
                {{ task.project.svc_coating.campaign }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <div class="info-box mb-6 pa-8" v-if="task.project.svc_coating.coating_option.length">
        <v-row>
          <v-col cols="3" class="info-box-headline text-heading-2">
            <v-icon class="mr-1" color="#4F55A7" size="6">$dot</v-icon>
            サービス施工
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col v-for="(item, index) in task.project.svc_coating.coating_option"
                class="info-box-work-information-headline sub" :key="`coating-option-${index}`">
                {{ item.text }}
                <template v-if="item.text_value">
                  ({{ item.text_value }})
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <!--      <v-container class="info-box mt-5 py-8">-->
      <!--        <v-row>-->
      <!--          <v-col cols="2" class="info-box-headline text-heading-2">-->
      <!--            <v-icon class="mr-4" color="#4F55A7" size="6">$dot</v-icon>-->
      <!--            アフター対応-->
      <!--          </v-col>-->
      <!--          <v-col cols="10" class="flex-grow-1">-->
      <!--            <v-row>-->
      <!--              <v-col cols="12">-->
      <!--                <v-row>-->
      <!--                  <template v-for="i in 3">-->
      <!--                    <v-col-->
      <!--                      :key="`a-${i}`"-->
      <!--                      cols="3"-->
      <!--                      class="info-box-work-information-headline sub"-->
      <!--                    >-->
      <!--                      令和2年3月4日-->
      <!--                    </v-col>-->
      <!--                    <v-col-->
      <!--                      :key="`b-${i}`"-->
      <!--                      cols="3"-->
      <!--                      class="info-box-work-information-headline sub"-->
      <!--                    >-->
      <!--                      再コート-->
      <!--                    </v-col>-->
      <!--                    <v-col-->
      <!--                      :key="`c-${i}`"-->
      <!--                      cols="3"-->
      <!--                      class="info-box-work-information-headline sub"-->
      <!--                    >-->
      <!--                      成功-->
      <!--                    </v-col>-->
      <!--                    <v-col-->
      <!--                      :key="`d-${i}`"-->
      <!--                      cols="3"-->
      <!--                      class="info-box-work-information-headline sub"-->
      <!--                    >-->
      <!--                      対応者：伊藤-->
      <!--                    </v-col>-->
      <!--                  </template>-->
      <!--                </v-row>-->
      <!--              </v-col>-->
      <!--            </v-row>-->
      <!--          </v-col>-->
      <!--        </v-row>-->
      <!--      </v-container>-->
    </template>

    <template v-else-if="task.service.id === 4">
      <v-container class="info-box mt-5 pa-8">
        <v-row v-for="serviceData in serviceData" :key="`service-data-${serviceData.name}`">
          <v-col cols="2" class="info-box-work-information-content-label text-center"
            v-if="task.project.svc_others[serviceData.name]">
            {{ serviceData.label }}
          </v-col>
          <v-col cols="10" class="info-box-work-information-content-content text-pre-wrap"
            v-if="task.project.svc_others[serviceData.name]">
            {{ task.project.svc_others[serviceData.name] }}
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-else>
      <div v-for="serviceDataFields in serviceData" :key="`service-data-${serviceDataFields.name}`">
        <v-container class="info-box mt-5 pa-8" v-if="checkforEmptyBox(serviceDataFields)">
          <v-row>
            <v-col cols="auto" class="pr-0">
              <v-icon color="#4F55A7" size="6">$dot</v-icon>
            </v-col>
            <v-col cols="3" class="info-box-headline text-heading-2" v-html="serviceDataFields.label">
            </v-col>
            <v-col cols="8" class="flex-grow-1">
              <v-row v-for="field in serviceDataFields.fields" :key="`field-${field.name}`" align="center">
                <template v-if="field.name === 'divider'">
                  <v-divider class="mx-3 my-4"></v-divider>
                </template>
                <template v-else>
                  <v-col cols="4" v-if="field.label == 'ワックス塗布階数'"
                    class="info-box-work-information-content-label text-heading-3">
                    範囲指定
                  </v-col>

                  <v-col cols="4" v-if="checkForEmpty(task.project[`svc_${serviceDataFields.name}`][
                    field.name
                  ]) && field.label != 'ワックス塗布階数'
                  " class="info-box-work-information-content-label text-heading-3">
                    {{ field.label }}
                  </v-col>
                  <v-col cols="8" class="info-box-work-information-content-content text-heading-3"
                    v-if="field.label == 'ワックス塗布階数'">
                    <span style="line-height: 2" v-html="getWixingValue(task.project.svc_floor)"></span>
                  </v-col>
                  <v-col cols="8" class="info-box-work-information-content-content text-heading-3" v-if="checkForEmpty(task.project[`svc_${serviceDataFields.name}`][
                    field.name
                  ]) && field.label != 'ワックス塗布階数'
                  ">
                    <template v-if="
                      Array.isArray(
                        task.project[`svc_${serviceDataFields.name}`][
                        field.name
                        ]
                      )
                    ">
                      <v-row>
                        <v-col cols="auto" v-for="(data, index) in task.project[
                          `svc_${serviceDataFields.name}`
                        ][field.name]" :key="`data-${field.name}-${index}`" :class="[
                          field.type === 'color'
                            ? 'd-flex align-center mr-3'
                            : ''
                        ]">
                          <template v-if="field.type === 'color'">
                            <!-- <v-icon
                            size="24"
                            :color="getColorObj(data.text).color"
                          >
                            $dot
                          </v-icon> -->
                            <span :style="{
                              backgroundColor: getColorObj(
                                data.text,
                                field.name
                              ).color
                            }" class="dot mr-1"></span>
                          </template>
                          {{ data.text }}
                        </v-col>
                      </v-row>
                    </template>

                    <template v-if="
                      isObjectOrString(
                        task.project[`svc_${serviceDataFields.name}`][
                        field.name
                        ]
                      )
                    ">
                      <v-row>
                        <v-col cols="auto">
                          {{
                          task.project[`svc_${serviceDataFields.name}`][
                          field.name
                          ]
                          }}
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>
  </div>
  <div v-else>
    <v-container>
      <v-row>
        <v-col class="text-center">
          <v-progress-circular indeterminate size="32"></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import serviceTypeBuildingDataFields from '@/components/admin/partials/Task/View/CustomerComponents/serviceTypeDataFields/building'
import serviceTypeCoatingDataFields from '@/components/admin/partials/Task/View/CustomerComponents/serviceTypeDataFields/coating'
import serviceTypeOtherDataFields from '@/components/admin/partials/Task/View/CustomerComponents/serviceTypeDataFields/other'
export default {
  name: 'WorkInformation',
  props: {
    task: {
      type: [Object, Array],
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      hasData: false,
      coating_genres: [
        'プレミアムコートEX',
        'プレミアムコートEXプラス',
        'プレミアムコートUVプラス',
        'シリコンコーティング',
        '水性コーティング',
        'プレミアムガード'
      ],
      tough_sealers: ['30%', '100%', '150%', 'なし'],
      colors: [
        {
          name: 'pat',
          colors: [
            { name: '白', color: '#FFFFFF' },
            { name: '赤', color: '#E74C3C' },
            { name: '青', color: '#5DADE2' },
            { name: '緑', color: '#2ECC71' },
            { name: '茶', color: '#AE7300' },
            { name: '黒', color: '#333333' },
            { name: 'その他', color: '#その他' }
          ]
        },
        {
          name: 'floor_color',
          colors: [
            { name: '白', color: '#FFFFFF' },
            { name: '薄茶', color: '#FFDC99' },
            { name: '中間', color: '#FFC34F' },
            { name: '茶', color: '#AE7300' },
            { name: '濃茶', color: '#664300' },
            { name: 'グレー', color: '#868686' },
            { name: '緑', color: '#1F9A00' },
            { name: '黒', color: '#333333' },
            { name: 'その他', color: '#ffffff' },
            { name: '青', color: '#0000ff' }
          ]
        }
      ]
    }
  },
  computed: {
    serviceType() {
      if (this.task.service.id === 0) return 'coating'
      if (this.task.service.id === 4) return 'other'
      return 'building'
    },
    serviceData() {
      if (this.task.service.id === 0) return serviceTypeCoatingDataFields
      if (this.task.service.id === 4) return serviceTypeOtherDataFields
      return serviceTypeBuildingDataFields
    },
    coatingRange() {
      if (this.task.project.svc_coating.coating_genre <= 2) {
        return this.task.project.svc_coating.coating_range1
      } else {
        return this.task.project.svc_coating.coating_range2
      }
    },
    coatingFloorType() {
      let types = [
        '3溝突板',
        '2溝突板',
        '1溝タイプ（フラット）',
        '1溝タイプ（ハピアタイプ）',
        '1溝タイプ（木目強調タイプ）',
        '化学床'
      ]

      return types[this.task.project.svc_coating.floor_genre]
    },
    removalWork() {
      if (this.task.project.svc_coating.removal_work === null) return ''
      return this.task.project.svc_coating.removal_work === 1 ? 'なし' : 'あり'
    },
    counterTable() {
      if (this.task.project.svc_coating.counter_table === null) return ''
      return this.task.project.svc_coating.counter_table === 1 ? 'なし' : 'あり'
    },
    assignedPartner() {
      return this.task.project.svc_coating.assigned_partner
    },
    partnerWorkrange() {
      return this.task.project.svc_coating.partner_workrange
    },
    memo() {
      return this.task.project.svc_coating.removal_work === 0 ? this.task.project.svc_coating.memo : ''
    },
    microMop() {
      if (this.task.project.svc_coating.micro_mop === null) return ''
      return this.task.project.svc_coating.micro_mop === 1 ? 'なし' : 'あり'
    },
    premiumCleaner() {
      if (this.task.project.svc_coating.premium_cleaner === null) return ''
      return this.task.project.svc_coating.premium_cleaner === 1
        ? 'なし'
        : 'あり'
    },
    toughSealer() {
      return this.tough_sealers[this.task.project.svc_coating.tough_sealer]
    },
    coatingGenre() {
      if (typeof this.task.project.svc_coating.coating_genre == 'number') {
        return this.coating_genres[this.task.project.svc_coating.coating_genre]
      }
      let coatings = this.task.project.svc_coating.coating_genre?.map(coating => this.coating_genres[coating]).join(',')
      return coatings
    },
  },
  methods: {
    getWixingValue(floor) {
      let waxing_place_text = '' //this.showText(Object.values(floor.waxing_place))

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_all_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_all)) {
        waxing_place_text += '全体：'
        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_all_direction) {
          waxing_place_text += floor.waxing_direction?.waxing_place_all_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_all) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_all_direction && floor.waxing_place.waxing_place_all) {
            waxing_place_text += ' (' + floor.waxing_place?.waxing_place_all + ")"
          } else {
            waxing_place_text += floor.waxing_place?.waxing_place_all
          }
        }
      }

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_1_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_1)) {
        waxing_place_text += '</br>'
        waxing_place_text += '部分1: '
        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_1_direction) {
          waxing_place_text += floor.waxing_direction?.waxing_place_part_1_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_1) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction && floor.waxing_place.waxing_place_part_1) {
            waxing_place_text += ' (' + floor.waxing_place?.waxing_place_part_1 + ")"
          } else {
            waxing_place_text +=floor.waxing_place?.waxing_place_part_1
          }
        }
      }

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_2)) {
        waxing_place_text += '</br>'
        waxing_place_text += '部分2: '

        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction) {
          waxing_place_text += floor.waxing_direction?.waxing_place_part_2_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_2) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction && floor.waxing_place.waxing_place_part_2) {
            waxing_place_text += ' (' + floor.waxing_place?.waxing_place_part_2 + ")"
          } else {
            waxing_place_text += floor.waxing_place?.waxing_place_part_2
          }
        }
      }

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_3_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_3)) {
        waxing_place_text += '</br>'
        waxing_place_text += '部分3: '

        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_3_direction) {
          waxing_place_text += floor.waxing_direction?.waxing_place_part_3_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_3) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_3_direction && floor.waxing_place.waxing_place_part_3) {
            waxing_place_text += ' (' + floor.waxing_place?.waxing_place_part_3 + ")"
          } else {
            waxing_place_text += floor.waxing_place?.waxing_place_part_3
          }
        }
      }

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_4_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_4)) {
        waxing_place_text += '</br>'
        waxing_place_text += '部分4: '

        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_4_direction) {
          waxing_place_text += floor.waxing_direction?.waxing_place_part_4_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_4) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_4_direction && floor.waxing_place.waxing_place_part_4) {
            waxing_place_text += ' (' + floor.waxing_place?.waxing_place_part_4 + ")"
          } else {
            waxing_place_text += floor.waxing_place?.waxing_place_part_4
          }
        }
      }

      return waxing_place_text;
    },
    getColorObj(name, field) {
      let colors = this.colors.find(color => color.name === field)?.colors
      return colors.find(color => color.name === name) || '#ffffff'
    },
    isObjectOrString(val) {
      return (
        (typeof val === 'object' &&
          val !== null &&
          !Array.isArray(val) &&
          typeof val === 'function') ||
        typeof val === 'string'
      )
    },
    checkForEmpty(val) {
      if (Array.isArray(val)) {
        return val.length ? true : false
      }
      return val ? true : false
    },
    checkforEmptyBox(service) {
      let display = false
      service.fields.forEach((field, idx) => {
        if (this.task.project[`svc_${service.name}`] && this.task.project[`svc_${service.name}`][field.name]) {
          if (Array.isArray(this.task.project[`svc_${service.name}`][field.name])) {
            if (this.task.project[`svc_${service.name}`][field.name].length > 0) {
              display = true
            }
          }
          else {
            display = true
          }
        }
      })
      return display
    }
  }
}
</script>

<style lang="scss" src="./WorkInformation.scss" scoped>
</style>
