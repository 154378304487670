export default [
  {
    name: 'task_description_1',
    label: '作業内容1'
  },
  {
    name: 'task_description_2',
    label: '作業内容2'
  },
  {
    name: 'task_description_3',
    label: '作業内容3'
  }
]
