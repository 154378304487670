<template>
  <div>
    <div class="customer-box pa-8 mb-6">
      <v-row dense align="center">
        <v-col cols="2">
          <div class="form-header">顧客情報</div>
        </v-col>
        <v-col cols="4">
          <div class="customer-name-bottom mb-1">
            {{ task.customer.name }}
          </div>
        </v-col>
        <v-col cols="auto" class="flex-grow-1 text-right">
          <v-btn
            :to="{
              name: 'CustomerViewDetailBasic',
              params: { customer_id: task.customer.id, site_id: task.site.id }
            }"
            @click="changeSelectedProject({id: task.project.id})"
            target="_self"
            rounded
            color="white"
          >
            詳細
            <v-icon right size="20"> $newTab </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="mt-3 mb-6">
        <v-row dense v-if="customerAddress && customerAddress.length && customerAddress!='-'">
          <v-col cols="2" class="customer-box-detail-headline">住所</v-col>
          <v-col
            cols="10"
            class="customer-box-detail-content"
            style="cursor: pointer"
            @click="openAddressInGoogleMaps('customer')"
          >
            {{ customerAddress }}
            <v-icon size="16">
              $newTab
            </v-icon>
          </v-col>
        </v-row>
        <v-row dense v-if="task?.customer?.phone1">
          <v-col cols="2" class="customer-box-detail-headline">電話</v-col>
          <v-col cols="10" class="customer-box-detail-content">
            {{ task.customer.phone1 }}
          </v-col>
        </v-row>
        <v-row dense v-if="task.customer.memo">
          <v-col cols="2" class="customer-box-detail-headline">メモ</v-col>
          <v-col cols="10" class="customer-box-detail-content white-space-pre lh-20">
            {{ task.customer.memo }}
          </v-col>
        </v-row>
      </div>

      <v-divider></v-divider>

      <div class="form-header mb-4 mt-8">
        物件 / サービス内容の選択
      </div>
      <template v-if="_expanded">
        <div class="mb-6">
          <v-row dense align="center" v-if="task?.site?.site_id || task?.site?.name">
            <v-col cols="2" class="customer-box-detail-headline">
              物件名
            </v-col>
            <v-col cols="4" class="customer-box-detail-content">
              {{ task.site.site_id }} {{ task.site.name }}
            </v-col>
          </v-row>
          <v-row dense align="center" v-if="task?.site.furigana_name">
            <v-col cols="2" class="customer-box-detail-headline">
              物件名（かな）
            </v-col>
            <v-col cols="4" class="customer-box-detail-content">
              {{ task.site.furigana_name }}
            </v-col>
          </v-row>
          <v-row dense v-if="task?.service?.name">
            <v-col cols="2" class="customer-box-detail-headline"
              >サービス
            </v-col>
            <v-col cols="10" class="customer-box-detail-content">
              <!-- Service type > Service category > Service items (multiple) -->
              {{ task.service.name }}
            </v-col>
          </v-row>
          <v-row dense v-if="siteAddress && siteAddress!=='-'">
            <v-col cols="2" class="customer-box-detail-headline">所在地</v-col>
            <v-col
              cols="10"
              class="customer-box-detail-content"
              style="cursor: pointer"
              @click="openAddressInGoogleMaps('site')"
            >
              {{ siteAddress }}
              <v-icon size="16">
                $newTab
              </v-icon>
            </v-col>
          </v-row>
          <v-row v-if="task.site.file_pdf" dense>
            <v-col cols="2" class="customer-box-detail-headline"
              >PDF
            </v-col>
            <v-col cols="10" class="customer-box-detail-content">
              <a :href="task.site.file_pdf_url" target="_blank" class="mr-2 blue--text text-decoration-underline">{{task.site.file_pdf_name}}</a>
            </v-col>
          </v-row>
        </div>

        <v-divider></v-divider>

        <div class="my-6">
          <v-row dense>
            <template v-if="projectData.partner_0">
              <v-col cols="2" class="customer-box-detail-headline">
                メーカー
              </v-col>
              <v-col cols="4" class="customer-box-detail-content">
                {{ projectData.partner_0.name }}
              </v-col>
            </template>
            <template v-if="detailInfo.on_site_monitorings[projectData.on_site_monitoring]">
            <v-col cols="2" class="customer-box-detail-headline">
              現場立ち合い
            </v-col>
            <v-col cols="4" class="customer-box-detail-content">
              {{
                detailInfo.on_site_monitorings[projectData.on_site_monitoring]
              }}
            </v-col>
            </template>
            <template v-if="projectData.partner_1">
              <v-col cols="2" class="customer-box-detail-headline">
                発注会社
              </v-col>
              <v-col cols="4" class="customer-box-detail-content">
                {{ projectData.partner_1.name }}
              </v-col>
            </template>

            <template v-if="projectData.partner_2">
              <v-col cols="2" class="customer-box-detail-headline">
                元請会社
              </v-col>
              <v-col cols="4" class="customer-box-detail-content">
                {{ projectData.partner_2.name }}
              </v-col>
            </template>
          </v-row>
        </div>

        <v-divider v-if="projectData.partner_0 || projectData.partner_1 || projectData.partner_2 || detailInfo.on_site_monitorings[projectData.on_site_monitoring]"></v-divider>

        <div class="mt-6">
          <v-row dense>
            <template v-for="itemInfo in filteredItemInfos">
              <v-col
                cols="2"
                class="customer-box-detail-headline"
                :key="`text-${itemInfo.keys}`"
              >
                {{ itemInfo.title }}
              </v-col>
              <v-col
                cols="4"
                class="customer-box-detail-content"
                :key="`value-${itemInfo.keys}`"
              >
                <template v-if="Number.isInteger(projectData[itemInfo.keys])">
                  {{ detailInfo[itemInfo.keys][projectData[itemInfo.keys]] }}
                </template>
                <template v-else v-for="item in projectData[itemInfo.keys]">
                  {{ item.text }}
                </template>
              </v-col>
            </template>
          </v-row>
        </div>
        <div class="mt-6" v-if="projectData.general_guideline">
          <v-divider></v-divider>
          <v-row no-gutters align="baseline" class="mt-4">
            <v-col cols="2" class="customer-box-detail-headline mb-2">
              現場に関するメモ
            </v-col>
            <v-col
              cols="10"
              class="customer-box-detail-content text-pre-wrap cust-line-height mb-2"
              style="line-height: 1.5"
            >
              {{ projectData.general_guideline || '-' }}
            </v-col>
          </v-row>
        </div>
      </template>
    </div>

    <work-information
      :loading="loading.work_info"
      :task="task"
    ></work-information>

    <claim :loading="loading.claim" v-if="task?.service?.name !== 'コーティング'"></claim>

    <after-service v-if="task?.service?.name === 'コーティング'" :task="task" :customer="task.customer" :customer_id="task.customer.id" :projectId="task.project_id" :siteId="task.site.id">
    </after-service>

    <photos :loading="loading.photos"></photos>

    <information :info="task"></information>
  </div>
</template>

<script>
import WorkInformation from '@/components/admin/partials/Task/View/CustomerComponents/WorkInformation'
import AfterService from '@/components/admin/partials/Task/View/AfterService'
import Claim from '@/components/admin/partials/Task/View/CustomerComponents/Claim'
import Photos from '@/components/admin/partials/Task/View/CustomerComponents/Photos'
import Information from '@/components/admin/partials/Task/View/Information'
import { mapMutations } from "vuex";

export default {
  name: 'Customer',
  components: { Information, Photos, Claim, WorkInformation , AfterService },
  props: {
    name: {
      type: String,
      default: '',
      required: true
    },
    task: {
      type: [Object, Array],
      required: true
    },
    expanded: {
      type: Boolean,
      default: true,
      required: false
    },
    loading: {
      type: Object,
      default: () => {},
      required: false
    }
  },
  computed: {
    _name: {
      get() {
        return this.name
      },
      set(newValue) {
        this.$emit('update:name', newValue)
      }
    },
    _expanded: {
      get() {
        return this.expanded
      },
      set(newValue) {
        this.$emit('update:expanded', newValue)
      }
    },

    projectData() {
      return this.task.site.projects.find(
        project => project.id === this.task.project.id
      )
    },

    customerAddress() {
      if (
        !this.task.customer.prefecture?.name &&
        !this.task.customer.city &&
        !this.task.customer.address
      )
        return '-'
      return `${this.task.customer.prefecture?.name || ''}${this.task.customer
        .city || ''}${this.task.customer.address || ''}`
    },

    siteAddress() {
      if (
        !this.task.site.prefecture?.name &&
        !this.task.site.city &&
        !this.task.site.address
      )
        return '-'
      return `${this.task.site.prefecture?.name || ''}${this.task.site.city ||
        ''}${this.task.site.address || ''}`
    },

    filteredItemInfos() {
      return this.itemInfos.filter(itemInfo => {
        if(this.projectData[itemInfo.keys] !== null && this.projectData[itemInfo.keys]?.length !== 0)
          return itemInfo
      })
    }
  },
  data() {
    return {
      itemInfos: [
        { title: '駐車場', keys: 'parking', has_extra: true },
        { title: 'ブレーカー', keys: 'lightening_braker' },
        { title: '鍵', keys: 'key', is_checkbox: true },
        { title: '電源', keys: 'outlet' },
        { title: '新築/中古', keys: 'site_age' },
        { title: '異常', keys: 'electrical_issues', has_extra: true },
        { title: '入居者', keys: 'residents' },
        { title: 'シャッター', keys: 'shutter' },
        { title: '家具類', keys: 'furnitures' },
        { title: 'カーテン', keys: 'curtain' },
        { title: 'エアコン', keys: 'air_conditioner' }
      ],
      detailInfo: {
        elevator: ['あり', 'なし'],
        parking: ['敷地内', 'コインパーキング', '近隣'],
        lightening_braker: ['入', '切'],
        key: ['立ち合い', 'キーバンカー', 'その他対応'],
        electrical_issues: ['あり', 'なし'],
        site_age: ['新築', '中古'],
        outlet: ['本設', '仮設'],
        residents: ['あり', 'なし'],
        shutter: ['あり', 'なし'],
        furnitures: ['あり', 'なし'],
        curtain: ['あり', 'なし'],
        on_site_monitorings: ['あり', 'なし'],
        air_conditioner: ['あり', '使用', '不使用', '不明', 'なし']
      }
    }
  },
  methods: {
    ...mapMutations(['changeSelectedProject']),
    openAddressInGoogleMaps(type) {
      let address = type === 'site' ? this.siteAddress : this.customerAddress
      window.open(
        `https://maps.google.com/?q=${encodeURIComponent(address)}`,
        '_blank'
      )
    }
  }
}
</script>

<style src="./Customer.scss" lang="scss" scoped></style>
