var render = function () {
  var _vm$task, _vm$task$customer, _vm$task2, _vm$task2$site, _vm$task3, _vm$task3$site, _vm$task4, _vm$task5, _vm$task5$service, _vm$task6, _vm$task6$service, _vm$task7, _vm$task7$service;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "customer-box pa-8 mb-6"
  }, [_c('v-row', {
    attrs: {
      "dense": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('div', {
    staticClass: "form-header"
  }, [_vm._v("顧客情報")])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "customer-name-bottom mb-1"
  }, [_vm._v(" " + _vm._s(_vm.task.customer.name) + " ")])]), _c('v-col', {
    staticClass: "flex-grow-1 text-right",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "to": {
        name: 'CustomerViewDetailBasic',
        params: {
          customer_id: _vm.task.customer.id,
          site_id: _vm.task.site.id
        }
      },
      "target": "_self",
      "rounded": "",
      "color": "white"
    },
    on: {
      "click": function click($event) {
        return _vm.changeSelectedProject({
          id: _vm.task.project.id
        });
      }
    }
  }, [_vm._v(" 詳細 "), _c('v-icon', {
    attrs: {
      "right": "",
      "size": "20"
    }
  }, [_vm._v(" $newTab ")])], 1)], 1)], 1), _c('div', {
    staticClass: "mt-3 mb-6"
  }, [_vm.customerAddress && _vm.customerAddress.length && _vm.customerAddress != '-' ? _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("住所")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "cols": "10"
    },
    on: {
      "click": function click($event) {
        return _vm.openAddressInGoogleMaps('customer');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.customerAddress) + " "), _c('v-icon', {
    attrs: {
      "size": "16"
    }
  }, [_vm._v(" $newTab ")])], 1)], 1) : _vm._e(), (_vm$task = _vm.task) !== null && _vm$task !== void 0 && (_vm$task$customer = _vm$task.customer) !== null && _vm$task$customer !== void 0 && _vm$task$customer.phone1 ? _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("電話")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.task.customer.phone1) + " ")])], 1) : _vm._e(), _vm.task.customer.memo ? _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("メモ")]), _c('v-col', {
    staticClass: "customer-box-detail-content white-space-pre lh-20",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.task.customer.memo) + " ")])], 1) : _vm._e()], 1), _c('v-divider'), _c('div', {
    staticClass: "form-header mb-4 mt-8"
  }, [_vm._v(" 物件 / サービス内容の選択 ")]), _vm._expanded ? [_c('div', {
    staticClass: "mb-6"
  }, [(_vm$task2 = _vm.task) !== null && _vm$task2 !== void 0 && (_vm$task2$site = _vm$task2.site) !== null && _vm$task2$site !== void 0 && _vm$task2$site.site_id || (_vm$task3 = _vm.task) !== null && _vm$task3 !== void 0 && (_vm$task3$site = _vm$task3.site) !== null && _vm$task3$site !== void 0 && _vm$task3$site.name ? _c('v-row', {
    attrs: {
      "dense": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 物件名 ")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.task.site.site_id) + " " + _vm._s(_vm.task.site.name) + " ")])], 1) : _vm._e(), (_vm$task4 = _vm.task) !== null && _vm$task4 !== void 0 && _vm$task4.site.furigana_name ? _c('v-row', {
    attrs: {
      "dense": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 物件名（かな） ")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.task.site.furigana_name) + " ")])], 1) : _vm._e(), (_vm$task5 = _vm.task) !== null && _vm$task5 !== void 0 && (_vm$task5$service = _vm$task5.service) !== null && _vm$task5$service !== void 0 && _vm$task5$service.name ? _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("サービス ")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.task.service.name) + " ")])], 1) : _vm._e(), _vm.siteAddress && _vm.siteAddress !== '-' ? _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("所在地")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "cols": "10"
    },
    on: {
      "click": function click($event) {
        return _vm.openAddressInGoogleMaps('site');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.siteAddress) + " "), _c('v-icon', {
    attrs: {
      "size": "16"
    }
  }, [_vm._v(" $newTab ")])], 1)], 1) : _vm._e(), _vm.task.site.file_pdf ? _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("PDF ")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "10"
    }
  }, [_c('a', {
    staticClass: "mr-2 blue--text text-decoration-underline",
    attrs: {
      "href": _vm.task.site.file_pdf_url,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.task.site.file_pdf_name))])])], 1) : _vm._e()], 1), _c('v-divider'), _c('div', {
    staticClass: "my-6"
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_vm.projectData.partner_0 ? [_c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" メーカー ")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.projectData.partner_0.name) + " ")])] : _vm._e(), _vm.detailInfo.on_site_monitorings[_vm.projectData.on_site_monitoring] ? [_c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 現場立ち合い ")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.detailInfo.on_site_monitorings[_vm.projectData.on_site_monitoring]) + " ")])] : _vm._e(), _vm.projectData.partner_1 ? [_c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 発注会社 ")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.projectData.partner_1.name) + " ")])] : _vm._e(), _vm.projectData.partner_2 ? [_c('v-col', {
    staticClass: "customer-box-detail-headline",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 元請会社 ")]), _c('v-col', {
    staticClass: "customer-box-detail-content",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.projectData.partner_2.name) + " ")])] : _vm._e()], 2)], 1), _vm.projectData.partner_0 || _vm.projectData.partner_1 || _vm.projectData.partner_2 || _vm.detailInfo.on_site_monitorings[_vm.projectData.on_site_monitoring] ? _c('v-divider') : _vm._e(), _c('div', {
    staticClass: "mt-6"
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.filteredItemInfos, function (itemInfo) {
    return [_c('v-col', {
      key: "text-".concat(itemInfo.keys),
      staticClass: "customer-box-detail-headline",
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(itemInfo.title) + " ")]), _c('v-col', {
      key: "value-".concat(itemInfo.keys),
      staticClass: "customer-box-detail-content",
      attrs: {
        "cols": "4"
      }
    }, [Number.isInteger(_vm.projectData[itemInfo.keys]) ? [_vm._v(" " + _vm._s(_vm.detailInfo[itemInfo.keys][_vm.projectData[itemInfo.keys]]) + " ")] : _vm._l(_vm.projectData[itemInfo.keys], function (item) {
      return [_vm._v(" " + _vm._s(item.text) + " ")];
    })], 2)];
  })], 2)], 1), _vm.projectData.general_guideline ? _c('div', {
    staticClass: "mt-6"
  }, [_c('v-divider'), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "no-gutters": "",
      "align": "baseline"
    }
  }, [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 現場に関するメモ ")]), _c('v-col', {
    staticClass: "customer-box-detail-content text-pre-wrap cust-line-height mb-2",
    staticStyle: {
      "line-height": "1.5"
    },
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.projectData.general_guideline || '-') + " ")])], 1)], 1) : _vm._e()] : _vm._e()], 2), _c('work-information', {
    attrs: {
      "loading": _vm.loading.work_info,
      "task": _vm.task
    }
  }), ((_vm$task6 = _vm.task) === null || _vm$task6 === void 0 ? void 0 : (_vm$task6$service = _vm$task6.service) === null || _vm$task6$service === void 0 ? void 0 : _vm$task6$service.name) !== 'コーティング' ? _c('claim', {
    attrs: {
      "loading": _vm.loading.claim
    }
  }) : _vm._e(), ((_vm$task7 = _vm.task) === null || _vm$task7 === void 0 ? void 0 : (_vm$task7$service = _vm$task7.service) === null || _vm$task7$service === void 0 ? void 0 : _vm$task7$service.name) === 'コーティング' ? _c('after-service', {
    attrs: {
      "task": _vm.task,
      "customer": _vm.task.customer,
      "customer_id": _vm.task.customer.id,
      "projectId": _vm.task.project_id,
      "siteId": _vm.task.site.id
    }
  }) : _vm._e(), _c('photos', {
    attrs: {
      "loading": _vm.loading.photos
    }
  }), _c('information', {
    attrs: {
      "info": _vm.task
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }