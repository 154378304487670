<template>
  <div v-if="!loading.all">
    <div class="task-container pa-9 pa-md-6 pa-lg-9">
      <v-container>
        <v-row align="center" class="mb-4">
          <v-col cols="6">
            <h3 class="page-title font-weight-bold mb-0">タスク詳細</h3>
          </v-col>
          <v-col cols="6" class="text-right">
            Task ID: {{ taskDetails.id }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mb-2" id="detailPart">
            <div
              class="text-title text-title-2 text-heading-2 font-weight-bold"
            >
              日付 / チーム / メモ
            </div>
            <div class="horizontal-line"></div>
          </v-col>
          <v-col>
            <div class="form-content">
              <detail :name.sync="task.name" :task="taskDetails"></detail>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" id="customerPart">
            <div
              class="text-title text-title-2 text-heading-2 font-weight-bold"
            >
              顧客 / 台帳
            </div>
            <div class="horizontal-line"></div>
          </v-col>
          <v-col>
            <div class="form-content">
              <customer
                :name.sync="task.name"
                :task="taskDetails"
                :expanded="true"
                :loading="loading"
              ></customer>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="intersection-helper" v-intersect="onIntersect"></div>

    <div
      class="create-task-footer px-6"
      :class="!isIntersecting ? 'shadow' : ''"
    >
      <v-row>
        <v-col cols="4" class="col-sm-2 col-lg-4">
          <v-btn
            :to="{ name: 'TaskEdit', params: { id: taskDetails.id } }"
            outlined
            color="#2F80ED"
          >
            編集する
          </v-btn>
        </v-col>

        <v-col cols="4" class="col-sm-6 col-lg-4 text-center">
          <v-btn
            class="mr-3"
              :color="
                on.detail
                  ? 'rgba(79, 85, 167, 0.45)'
                  : 'rgba(79, 85, 167, 0.1)'
              "
            depressed
            rounded
            @click="$vuetify.goTo('#detailPart', { offset: offset })"
          >
            日付 / チーム / メモ
          </v-btn>
          <v-btn
              :color="
                on.customer
                  ? 'rgba(79, 85, 167, 0.45)'
                  : 'rgba(79, 85, 167, 0.1)'
              "
            depressed
            rounded
            @click="$vuetify.goTo('#customerPart', { offset: offset })"
          >
            顧客 / 台帳
          </v-btn>
        </v-col>

        <v-col cols="4" class="d-flex justify-end col-sm-4 col-lg-4">
          <v-btn text class="mr-3" style="display:none">キャンセル</v-btn>
          <v-btn :loading="loading.button" @click="sendEmail" outlined color="#2F80ED">
            <v-icon size="32" left>$sendMail</v-icon>
            通知
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
  <div v-else>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-progress-circular size="48" indeterminate></v-progress-circular>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Customer from '@/components/admin/partials/Task/View/Customer'
import Detail from '@/components/admin/partials/Task/View/Detail'
export default {
  name: 'ViewTask',
  components: { Detail, Customer },
  props: ['id'],
  data() {
    return {
      task: {
        name: 'test',
        timeStart: '08:00',
        timeGather: '07:30',
        meetingPlace: '',
        leader: 0,
        vehicle: 0
      },
      on: {
        detail: true,
        customer: false
      },
      offset: 75,
      expanded: true,
      isIntersecting: false,
      btnActive: 'rgba(79, 85, 167, 0.45)',
      btnRegular: 'rgba(79, 85, 167, 0.1)',
      loading: {
        all: true,
        work_info: true,
        claim: true,
        photos: true,
        button: false
      }
    }
  },
  computed: {
    taskDetails() {
      return this.$store.getters.task
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  destroy() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods: {
    updateScroll() {
      let scrollPosition = window.scrollY
      this.on = { customer: false, detail: false }

      if (scrollPosition >= 863) {
        this.on.customer = true
      } else if (scrollPosition >= 0) {
        this.on.detail = true
      }
    },
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting
    },
    async getTaskById(id) {
      await this.$store.dispatch('GET_TASK_BY_ID', id)
      this.loading.all = false
      this.loading.work_info = false
      document.title = `${this.taskDetails.customer.name} - ${document.title}`
    },
    fetchTaskClaims(id) {
      this.$store.dispatch('CLAIMS_BY_TASK_ID', id)
      this.loading.claim = false
    },
    fetchTaskImages() {
      let param = {
        photo: {
          id: this.taskDetails.customer.id,
          site_id: this.taskDetails.site.id,
          paginate: 10
        }
      }
      this.$store.dispatch('GET_ALL_PHOTO_BY_CUSTOMER_ID', param.photo)
      this.loading.photos = false
    },
    sendEmail() {
      this.loading.button = true
      let id = this.$route.params.id
      this.$store.dispatch('SEND_TASK_EMAIL', id)
        .finally(() => {
          this.$store.dispatch('GET_TASK_BY_ID', id)
          this.loading.button = false
        })
    }
  },
  async created() {
    let id = this.$route.params.id
    await this.getTaskById(id)
    this.fetchTaskClaims(id)
    this.fetchTaskImages(id)
  }
}
</script>

<style src="./View.scss" lang="scss" scoped></style>
