<template>
  <v-row>
    <v-col cols="6" class="mb-6">
      <div class="detail-header font-weight-bold mb-4">
        {{ task.date }} （{{getDateFormat(task.date)}}） {{ task.task_start_time }} 開始 <span
          v-if="task && task.task_end_time"> {{ task?.task_end_time }} 完了時刻 </span>


      </div>
      <div class="detail-subheader">集合 {{ task.gathering_time }} @ {{task.gathering_place}}</div>
      <div class="mt-4" v-if="weatherInfo">
        <v-row align="center" class="mb-2">
          <v-col cols="auto">
            <v-avatar :class="weatherBackgroundClass">
              <v-img :src="weatherInfo" max-width="60" contain />
            </v-avatar>
          </v-col>
          <v-col cols="auto">
            {{ weatherDescription }}
          </v-col>
        </v-row>
        <div class="weather-last-update">
          最終更新：{{ weatherLastUpdate }}
        </div>
      </div>
    </v-col>

    <v-col cols="6" class="text-right" align-self="center">
      <v-avatar :color="task.service.background_color" size="64">
        <span :style="`color: ${task.service.font_color}`">
          {{ task.service.name }}
        </span>
      </v-avatar>
    </v-col>

    <v-col cols="12" class="detail-header-participant text-heading-3"
      v-if="typeExists(task.task_member, 0) || typeExists(task.task_member, 1) || typeExists(task.task_member, 2)">
      参加メンバー
    </v-col>

    <v-col cols="12" class="mb-6" :class="{'no-members-case': !task.task_member.length}">
      <template v-if="task.task_member.length && typeExists(task.task_member, 0)">
        <v-row class="mb-2">
          <v-col>
            <div class="detail-content-participant-header text-heading-3">
              通常業務
            </div>
            <div class="horizontal-line"></div>
          </v-col>
        </v-row>
        <v-row dense>
          <template>
            <v-col v-for="member in getMembers(taskMembers, 0)" :key="`participant-${member.id}`" cols="auto" class="text-center">
              <v-avatar :class="isLeader(member) ? 'leader' : ''" size="51">
                <img alt="avatar" :src="getMemberPhoto(member)" />
              </v-avatar>
              <div class="participant-name text-center text-heading-3 mt-2">
                {{ member.last_name }}
              </div>
            </v-col>
          </template>
        </v-row>
      </template>
      <template v-if="task.task_member.length && typeExists(task.task_member, 1)">
        <v-row class="mb-2">
          <v-col>
            <div class="detail-content-participant-header text-heading-3">
              日常清掃員
            </div>
            <div class="horizontal-line"></div>
          </v-col>
        </v-row>
        <v-row dense>
          <template>
            <v-col v-for="member in getMembers(taskMembers, 1)" :key="`participant-${member.id}`" cols="auto" class="text-center">
              <v-avatar :class="isLeader(member) ? 'leader' : ''" size="51">
                <img alt="avatar" :src="getMemberPhoto(member)" />
              </v-avatar>
              <div class="participant-name text-center text-heading-3 mt-2">
                {{ member.last_name }}
              </div>
            </v-col>
          </template>
        </v-row>
      </template>
      <template v-if="task.task_member.length && typeExists(task.task_member, 2)">
        <v-row class="mb-2">
          <v-col>
            <div class="detail-content-participant-header text-heading-3">
              協業会社
            </div>
            <div class="horizontal-line"></div>
          </v-col>
        </v-row>
        <v-row dense>
          <template>
            <v-col v-for="member in getMembers(taskMembers, 2)" :key="`participant-${member.id}`" cols="auto" class="text-center">
              <v-avatar :class="isLeader(member) ? 'leader' : ''" size="51">
                <img alt="avatar" :src="getMemberPhoto(member)" />
              </v-avatar>
              <div class="participant-name text-center text-heading-3 mt-2">
                {{ member.last_name }}
              </div>
            </v-col>
          </template>
        </v-row>
      </template>
    </v-col>

    <v-col v-if="task.task_freelancer_users.length" cols="12" class="mb-6">
      <v-row>
        <v-col>
          <div class="detail-content-participant-header text-heading-3">
            外注先
          </div>
          <div class="horizontal-line"></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" class="text-heading-3">
          <template v-if="task.task_freelancer_users.length">
            <v-row>
              <v-col cols="auto" v-for="outsource in task.task_freelancer_users" :key="outsource.name"
                class="text-center">
                <v-avatar size="46">
                  <img alt="avatar" :src="getPartnerProfilePicture(outsource)" />
                </v-avatar>
                <div class="participant-name mt-2">
                  {{ outsource.name }}
                  <span class="gray--text">
                    ({{ outsource.pivot.freelancers_count }})
                  </span>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            -
          </template>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-if="task.car" cols="12" class="car-info mb-6">
      <div class="detail-content-participant-header-2 mb-4 text-heading-2">
        車
      </div>
      <div class="d-inline-block text-heading-3">
        {{ task.car ? task.car.car_class + ' ' + task.car.number_plate : '-' }}
      </div>
      <div class="d-inline-block ml-8" v-if="task.ETC_card">
        <span>ETC</span>
        <v-avatar size="32" :color="task.ETC_card ? '#4F55A7' : '#DEDEDE'" class="ml-4"
          :class="task.ETC_card ? 'white--text' : ''">
          {{ task.ETC_card ? '有' : '無' }}
        </v-avatar>
      </div>

      <div class="d-inline-block ml-8" v-if="task.gasoline_card">
        <span>ガソリンカード</span>
        <v-avatar size="32" :color="task.gasoline_card ? '#4F55A7' : '#DEDEDE'" class="ml-4"
          :class="task.gasoline_card ? 'white--text' : ''">
          {{ task.ETC_card ? '有' : '無' }}
        </v-avatar>
      </div>
    </v-col>

    <v-col v-if="task.memo" cols="12" class="memo-info mb-6">
      <div class="detail-content-participant-header-2 text-heading-2 mb-4">
        通常メモ
      </div>
      <div class="memo text-heading-3">
        <p>{{ task.memo ? task.memo : '-' }}</p>
      </div>
    </v-col>

    <v-col v-if="task.important_notice" cols="12" class="memo-info mb-6">
      <div class="detail-content-participant-header-2 text-heading-2 mb-4">
        重要連絡
      </div>
      <div class="memo-important text-heading-3">
        <p>{{ task.important_notice ? task.important_notice : '-' }}</p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from '@/plugins/dayjs'
export default {
  name: 'Detail',
  props: {
    task: {
      type: [Array, Object],
      required: true
    }
  },
  computed: {
    taskMembers() {
      let members = []
      if (this.task.leader) {
        members.push(this.task.leader)
      }
      members.push(
        ...this.task.task_member.filter(member => !this.isLeader(member))
      )
      return members
    },
    weatherDescription() {
      if (!this.task.weather_info) return ''
      return this.$t(`weather_${this.task.weather_info.weather[0].id}`)
    },
    weatherInfo() {
      if (!this.task.weather_info) return ''
      return `http://openweathermap.org/img/wn/${this.task.weather_info.weather[0].icon}@2x.png`
    },
    weatherLastUpdate() {
      if (!this.task.weather_last_update) return ''
      return dayjs(this.task.weather_last_update).format('YYYY-MM-DD HH:mm')
    },
    weatherBackgroundClass() {
      if (!this.task.weather_info) return ''
      return `background-${this.task.time === 0 ? 'day' : 'night'}`
    }
  },
  methods: {
    getMembers(mebmers, type) {
      let employees = []
      Object.keys(mebmers).forEach((key) => {
        if (mebmers[key].employee_type == type) {
          employees.push(mebmers[key])
        }
      })
      return employees
    },
    typeExists(members, type) {
      let exist = members.some(element => {
        if (element.employee_type === type) {
          return true;
        }
        return false;
      });
      return exist
    },

    isLeader(member) {
      return this.task.leader?.id === member.id
    },

    getMemberPhoto(member) {
      if (member.profile_image)
        return `${member.profile_image_url}`
      return require('@/assets/images/user-placeholder.png')
    },

    getPartnerProfilePicture(partner) {
      if (partner.profile_image)
        return `${partner.profile_image_url}`
      return require('@/assets/images/user-placeholder.png')
    },

    getDateFormat(task_date) {
      return dayjs(task_date).format(`dd`)
    }
  }
}
</script>

<style lang="scss" src="./Detail.scss" scoped>

</style>
