export default [
  {
    name: 'work_information',
    label: '作業情報',
    fields: [
      {
        name: 'area',
        label: ''
      },
      {
        name: 'range',
        label: '使用洗剤'
      },
      {
        name: 'detergent_method',
        label: '洗剤塗布方法'
      },
      {
        name: 'cleaning_method',
        label: '基本洗浄方法'
      },
      {
        name: 'polisher',
        label: '使用ポリッシャー'
      },
      {
        name: 'cleaning_machine',
        label: '使用自洗機'
      },
      {
        name: 'pat',
        type: 'color',
        label: '使用パット'
      },
      {
        name: 'certain_place_method',
        label: '場所指定洗浄方法'
      },
      {
        name: 'certain_place_explanation',
        label: '指定場所'
      },
      {
        name: 'divider'
      },
      {
        name: 'waxing_place',
        label: 'ワックス塗布階数'
      },
      {
        name: 'waxing_product',
        label: '使用ワックス'
      },
      {
        name: 'waxing_rule',
        label: '注意事項'
      }
    ]
  },
]
