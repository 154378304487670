var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "task-information pa-8 mt-5"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "task-information-headline mb-3",
    attrs: {
      "cols": "2"
    }
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v("タスク管理情報")])]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_vm._v(" 作成日時 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("yearMonthDateTime")(_vm.info.created_at)))]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" ( " + _vm._s(_vm.info.created_by.full_name) + " ) ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_vm._v(" 更新日時 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("yearMonthDateTime")(_vm.info.updated_at)) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" ( " + _vm._s(_vm.info.updated_by ? _vm.info.updated_by.full_name : '-') + " ) ")])], 1)], 1), _vm.info.notified_at ? [_c('v-col', [_c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" 通知 ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("yearMonthDate")(_vm.info.notified_at)) + " ")])], 1)], 1)] : _vm._e()], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }