<template>
  <v-container v-if="customerCoatingAfterCount > 0">
    <v-row>
      <v-col cols="12">
        <Table
           :loading="false"
          :headers="[]"
          :items="afterServices"
          :total-records="customerCoatingAfterCount"
          :footer="false"
          disable-pagination
          class="font-weight-medium mb-4"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <div class="table-head">
                <div
                  class="table-content-top d-flex justify-center align-center"
                >
                  #
                </div>
              </div>
              <div
                v-for="(item, index) in items"
                :key="`${index}-${item.name}`"
                class="d-flex align-center single-row justify-space-between"
              >
                <div class="d-flex">
                  <div class="idx-number">
                    <span>
                      {{ index + 1 }}
                    </span>
                  </div>
                  <div class="single-record d-flex">
                    <div class="record">
                      <div class="d-flex first-row">
                        <span> {{ item.date | formatDate }} </span>
                        <div class="d-flex">
                          <span class="column-key">対応種別：</span>
                          <span>{{ paidStatus[item.is_paid] }}</span>
                        </div>
                        <div class="d-flex">
                          <span class="column-key">部屋番号：</span>
                          <span>{{ item.room_number }}</span>
                        </div>
                      </div>
                      <div>
                        <span class="column-key">依頼項目：</span>
                        <span>
                          {{ item.reason ? showTextReason(item.reason) : '-' }}
                        </span>
                      </div>
                      <div>
                        <span class="column-key">対応方法：</span>
                        <span
                          >{{ item.method ? showText(item.method) : '-' }}
                        </span>
                      </div>
                      <div>
                        <td>
                          <span class="column-key">対応結果：</span>
                          <span>
                            {{
                              item.after_treatment
                                ? showText(item.after_treatment)
                                : '-'
                            }}</span
                          >
                        </td>
                      </div>
                      <div>
                        <td>
                          <span class="column-key">対応者：</span>
                          <span>
                            {{ item.users ? showUser(item.users) : '-' }}</span
                          >
                        </td>
                      </div>
                      <div class="d-flex">
                          <span class="column-key">対応者初見：</span>
                          <span class="white-space-pre">{{ item.memo || '-' }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="text-align:center" class="list-link" v-if="customerCoatingAfterCount > 10" @click="redirectToList()">
              [ 一覧を見る ]
              </div>
            </tbody>
          </template>
          <template v-slot:top>
            <div class="mb-6">
              <v-row align="end">
                <v-col cols="auto" class="text-heading-1">
                  物件に関連したアフター対応一覧
                </v-col>
                <v-col cols="auto" class="ml-4 text-heading-3">
                  全 ({{ customerCoatingAfterCount }}) 件 あります。
                </v-col>
                <v-col cols="auto" class="flex-grow-1 text-right">
                  <v-btn rounded color="#fff" @click="redirectToList()">一覧
                  <v-icon class="ml-1">$newTab</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </template>
        </Table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from '@/plugins/dayjs'
import Table from '@/components/admin/partials/Table/Table'
import UpdateList from '@/components/admin/partials/Customer/View/Basic/UpdateHistory/UpdateList'
export default {
  name: 'AfterService',
  props: {
    customer_id: {
      type: [Number, String],
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    projectId: {
      type: [Number, String]
    },
    siteId: {
        type: [Number,String]
    },
    task: {
      type: Object
    }
  },
  components: { Table },
  data() {
    return {
      items: {
        after_treatments: [
          {
            text: '成功',
            value: 0
          },
          {
            text: '微妙',
            value: 1
          },
          {
            text: 'お客様納得',
            value: 2
          },
          {
            text: '失敗',
            value: 3
          },
          {
            text: 'その他',
            extra_type: 'with_text',
            value: 5
          }
        ],
        methods: [
          {
            text: '訪問再コート',
            value: 0
          },
          {
            text: '社内持ち込み再コート',
            value: 1
          },
          {
            text: 'スプレーぼかし',
            value: 2
          },
          {
            text: 'リペア含む',
            value: 3
          },

          {
            text: '剥離再コート',
            value: 4
          },
          {
            text: 'アルコール除去',
            value: 5
          },
          {
            text: '重ね散布',
            value: 6
          },

          {
            text: 'その他',
            extra_type: 'with_text',
            value: 7
          }
        ]
      },
      addServiceDialog: false,
      claimItem: {},
      page: 1,
      pageCount: 5,
      paidStatus: {
        0: '無償',
        1: '有償'
      }
    }
  },

  filters: {
    formatDate(value) {
      if (value) {
        return dayjs(value).format('YYYY/MM/DD')
      }
    }
  },

  mounted() {
    this.getDataFromApi()
    this.updateCustomerAfterServices(1)
  },
 computed: {
    ...mapGetters([
      'allUsersList',
      'allCustomerCoatingAfters',
      'customerAfterServicePagination',
      'customerAfterServiceLoading',
      'customerCoatingAfterCount'
    ]),
    afterServices() {
      return this.allCustomerCoatingAfters
    },
    project() {
      return this.selectedSite?.projects?.find(
        project => project.id === this.projectId
      )
    },
    selectedSite() {
      return this.customer?.sites?.find(site => site.id == this.siteId)
    },
    pagination() {
      return this.customerAfterServicePagination
    },
  },

  methods: {
    showTextReason(array) {
      if (array) {
        let text = array
          .map(c => {
            if (c) {
              if (c.extra_type == 'with_text') {
                return (
                  c.text + ' (' + (c.text_value ? c.text_value : c.value) + ')'
                )
              }
              return c.text ? c.text : c
            } else {
              return ''
            }
          })
          .join(', ')

        return text
      }
    },
    showUser(array) {
      if (Array.isArray(array) && array?.length > 0) {
        let text = array
          .map(c => {
            return `${c?.last_name} ${c?.first_name}`
          })
          .join(', ')

        return text
      }
    },
    showText(array) {
      if (array) {
        let text = array
          .map(c => {
            if (c) {
              if (c.extra_type == 'with_text') {
                return (
                  c.text + ' (' + (c.text_value ? c.text_value : c.value) + ')'
                )
              }
              return c.text ? c.text : c
            } else {
              return ''
            }
          })
          .join(', ')

        return text
      }
    },

    getIndexByItem(data, item) {
      return data
        .map(item => `${item.text}${item.id}`)
        .indexOf(`${item.text}${item.id}`)
    },

    changeInput(e, data, item) {
      let d = data[this.getIndexByItem(data, item)]
      if (d.hasOwnProperty('text_value') && !e) {
        delete d.text_value
        return
      }
      d.text_value = e
    },

    getDateFormat(date, ln) {
      if (ln == 'ja') {
        return dayjs(date).format(`YYYY年MM月DD日`)
      } else {
        return dayjs(date).format(`YYYY/MM/DD H:mm`)
      }
    },

    addClaim() {
      this.addServiceDialog = true
      this.claimItem = {}
    },

    editService(item) {
      this.addServiceDialog = true
      this.claimItem = item
    },

    getDataFromApi() {
      this.getDataFromApiCategory()
    },

    async getDataFromApiCategory() {
      let params = {
        tag_type: 2
      }

      await this.$store.dispatch('CLAIM_CATEGORY_GET_ALL', params)
    },

    updateCustomerAfterServices(page) {
      this.page = page
      let param = {
        customer_id: this.customer_id,
        site_id: this.siteId,
        project_id: this.projectId,
        coating_id: this.task?.project?.svc_coating?.id,
        entries: 10,
      }
      this.$store.dispatch('CUSTOMER_GET_ALL_AFTER_SERVICE', param)
    },
    redirectToList() {
        this.$router.push({
            name: 'CustomerViewDetailAfterService',
            params: {
                customer_id: this.customer_id,
                site_id: this.siteId
            }
        })
    }
  }
}
</script>

<style lang="scss" src="./AfterService.scss" scoped></style>
