<template>
  <div class="mb-6 pa-8" v-if="task.claims.length">
    <v-row>
      <v-col cols="12">
        <v-row align="center">
          <v-col cols="auto" class="text-heading-1 color-000">
            物件に関連するクレーム一覧
          </v-col>
          <v-col cols="auto">
            <div class="claims-count">
              全 ({{ task.claims.length }}) 件 あります
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn
              target="_self"
              :to="{
                name: 'CustomerViewDetailClaim',
                params: {
                  customer_id: task.customer.id,
                  site_id: task.site.id
                }
              }"
              rounded
              color="white"
            >
              一覧
              <v-icon right size="24"> $newTab </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="text-center" v-if="loading">
        <v-progress-circular size="32" indeterminate />
      </v-col>
      <v-col v-else cols="12" class="flex-grow-1">
        <v-row>
          <template v-if="task.claims.length > 0">
            <v-col
              v-for="(claim, index) in task.claims"
              :key="`claim-${index}`"
              cols="12"
              class="single-record"
            >
              <div class="d-flex align-center">
                <div class="idx">
                  {{ index + 1 }}
                </div>
                <v-row offset="2">
                  <v-col cols="12 color-000" offset="2">{{ claim.date | formatDate() }}</v-col>
                  <v-col cols="2" class="column-key text-right">メンバー:</v-col>
                  <v-col cols="10" class="color-000">
                  <span class="font-weight-bold">
                  {{getLeader(claim)}}
                  </span>

                  {{getMembers(claim)}}</v-col>
                  <v-col cols="2" class="form-header text-right column-key"> 分類: </v-col>
                  <v-col cols="10" style="margin-top: -6px">
                    <v-chip
                      color="#8C5F5F"
                      text-color="white"
                      v-for="(category, index) in claim.categories"
                      :key="index"
                      label
                      small
                      class="mr-3 mb-2"
                    >
                      {{ category.name }}
                    </v-chip>
                  </v-col>
                  <v-col cols="2" class="form-header text-right column-key"
                    >クレーム内容:
                  </v-col>
                  <v-col cols="10" class="claim-des color-000">{{
                    claim.description
                  }}</v-col>

                  <v-col cols="2" class="form-header text-right column-key"> 対応: </v-col>
                  <v-col cols="10" class="claim-des color-000">{{
                    claim.response
                  }}</v-col>
                </v-row>
              </div>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from '@/plugins/dayjs'
import { mapGetters } from 'vuex'
export default {
  name: 'Claim',
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  filters: {
    formatDate(value) {
      return dayjs(value).format('YYYY年 M月 D日')
    }
  },
  computed: {
    ...mapGetters(['task', 'allClaimCategories']),
    claims() {
      return this.$store.getters.claimsByTaskId
    }
  },
  methods: {
    getMembers(claim) {
      return claim?.members?.map(member => {
          return `${member.last_name || ''} ${member.first_name || ''}`
      }).join(', ')
    },
    getLeader(claim) {
      let leader = claim?.members?.find(member => member.id === claim?.task?.leader_id)
      return leader ? `${claim.leader.last_name || ''} ${claim.leader.first_name || ''}` : ''
    }
  }
}
</script>

<style lang="scss" src="./Claim.scss" scoped></style>
