var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.task.claims.length ? _c('div', {
    staticClass: "mb-6 pa-8"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-heading-1 color-000",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 物件に関連するクレーム一覧 ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "claims-count"
  }, [_vm._v(" 全 (" + _vm._s(_vm.task.claims.length) + ") 件 あります ")])]), _c('v-spacer'), _c('v-col', {
    staticClass: "text-right"
  }, [_c('v-btn', {
    attrs: {
      "target": "_self",
      "to": {
        name: 'CustomerViewDetailClaim',
        params: {
          customer_id: _vm.task.customer.id,
          site_id: _vm.task.site.id
        }
      },
      "rounded": "",
      "color": "white"
    }
  }, [_vm._v(" 一覧 "), _c('v-icon', {
    attrs: {
      "right": "",
      "size": "24"
    }
  }, [_vm._v(" $newTab ")])], 1)], 1)], 1)], 1), _vm.loading ? _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "size": "32",
      "indeterminate": ""
    }
  })], 1) : _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_vm.task.claims.length > 0 ? _vm._l(_vm.task.claims, function (claim, index) {
    return _c('v-col', {
      key: "claim-".concat(index),
      staticClass: "single-record",
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "d-flex align-center"
    }, [_c('div', {
      staticClass: "idx"
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('v-row', {
      attrs: {
        "offset": "2"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12 color-000",
        "offset": "2"
      }
    }, [_vm._v(_vm._s(_vm._f("formatDate")(claim.date)))]), _c('v-col', {
      staticClass: "column-key text-right",
      attrs: {
        "cols": "2"
      }
    }, [_vm._v("メンバー:")]), _c('v-col', {
      staticClass: "color-000",
      attrs: {
        "cols": "10"
      }
    }, [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.getLeader(claim)) + " ")]), _vm._v(" " + _vm._s(_vm.getMembers(claim)))]), _c('v-col', {
      staticClass: "form-header text-right column-key",
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" 分類: ")]), _c('v-col', {
      staticStyle: {
        "margin-top": "-6px"
      },
      attrs: {
        "cols": "10"
      }
    }, _vm._l(claim.categories, function (category, index) {
      return _c('v-chip', {
        key: index,
        staticClass: "mr-3 mb-2",
        attrs: {
          "color": "#8C5F5F",
          "text-color": "white",
          "label": "",
          "small": ""
        }
      }, [_vm._v(" " + _vm._s(category.name) + " ")]);
    }), 1), _c('v-col', {
      staticClass: "form-header text-right column-key",
      attrs: {
        "cols": "2"
      }
    }, [_vm._v("クレーム内容: ")]), _c('v-col', {
      staticClass: "claim-des color-000",
      attrs: {
        "cols": "10"
      }
    }, [_vm._v(_vm._s(claim.description))]), _c('v-col', {
      staticClass: "form-header text-right column-key",
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" 対応: ")]), _c('v-col', {
      staticClass: "claim-des color-000",
      attrs: {
        "cols": "10"
      }
    }, [_vm._v(_vm._s(claim.response))])], 1)], 1)]);
  }) : _vm._e()], 2)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }