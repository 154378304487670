<template>
  <div class="task-information pa-8 mt-5">
    <v-row>
      <v-col cols="2" class="task-information-headline mb-3">
        <h3 class="mb-0">タスク管理情報</h3>
      </v-col>
      <v-col cols="10">
        <v-row dense>
          <v-col cols="12">
            <v-row no-gutters>
              <v-col cols="1"> 作成日時 </v-col>
              <v-col cols="2"> {{ info.created_at | yearMonthDateTime }}</v-col>
              <v-col cols="2"> ( {{ info.created_by.full_name }} ) </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row no-gutters>
              <v-col cols="1"> 更新日時 </v-col>
              <v-col cols="2"> {{ info.updated_at | yearMonthDateTime }} </v-col>
              <v-col cols="2"> ( {{ info.updated_by ? info.updated_by.full_name : '-' }} ) </v-col>
            </v-row>
          </v-col>
          <template v-if="info.notified_at">
          <v-col>
            <v-row>
              <v-col cols="6" class="text-right"> 通知 </v-col>
              <v-col cols="6"> {{ info.notified_at | yearMonthDate }} </v-col>
            </v-row>
          </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'Information',
  props: {
    info: {
      type: [Array, Object],
      required: true
    }
  },
  filters: {
    yearMonth(date) {
      if (!date) {
        return '-'
      }
      return dayjs(date).format('YYYY/MM')
    },
    yearMonthDate(date) {
      if (!date) {
        return '-'
      }
      return dayjs(date).format('YYYY/MM/DD')
    },
    yearMonthDateTime(date) {
      if (!date) {
        return '-'
      }
      return dayjs(date).format('YYYY/MM/DD HH:mm')
    }
  }
}
</script>

<style lang="scss" src="./Information.scss" scoped></style>
